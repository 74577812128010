import React, { useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import anime from "animejs/lib/anime.es.js";

import bannerDesktopImage from "../../images/banner.png";
import bannerMobileImage from "../../images/logo.png";
import "./Nav.css";

function Nav() {
  const [windowSize, setWindowSize] = useState([0, 0]);
  const [navActive, setNavActive] = useState(false);
  const navUl = useRef(null);

  // React Hooks
  useLayoutEffect(() => {
    setWindowSize([window.innerWidth, window.innerHeight]);
    window.addEventListener("resize", () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    });
    window.addEventListener("orientationchange", () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    });
    return;
  }, []);

  // Handlers
  function handleBurgerClick(e) {
    // When Nav is open, close it
    if (navActive) {
      closeNav();
    } else {
      // Else just open it
      openNav();
    }
  }

  function handleNavLinkClick() {
    closeNav();
  }

  // Functions
  function openNav() {
    window.scrollTo(0, 0);
    document.querySelector("html").style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    document.querySelector("html").style.touchAction = "none";
    document.body.style.touchAction = "none";
    anime({
      targets: ".Nav__header__nav ul",
      translateX: ["100vw", 0],
      duration: 750,
    });
    setNavActive(true);
  }

  function closeNav() {
    document.querySelector("html").style.overflowY = "auto";
    document.body.style.overflowY = "auto";
    document.querySelector("html").style.touchAction = "auto";
    document.body.style.touchAction = "auto";
    anime({
      targets: ".Nav__header__nav ul",
      translateX: [0, "100vw"],
      duration: 750,
    });
    setNavActive(false);
  }

  return (
    <header>
      <img
        src={windowSize[0] <= 600 ? bannerMobileImage : bannerDesktopImage}
        className="Nav__header__bannerImage"
        alt="Banner"
      />
      <nav className="Nav__header__nav">
        <h1 className="Nav__title">Herberg Breugelhoeve</h1>
        <ul ref={navUl}>
          <li onClick={handleNavLinkClick}>
            <Link to="/">Home</Link>
          </li>
          <li onClick={handleNavLinkClick}>
            <Link to="/menu">Menu</Link>
          </li>
          <li onClick={handleNavLinkClick}>
            <Link to="/afbeeldingen">Afbeeldingen</Link>
          </li>
          <li onClick={handleNavLinkClick}>
            <Link to="/contacteer-ons">Contacteer ons</Link>
          </li>
          <li onClick={handleNavLinkClick}>
            <Link to="/betaalmethoden">Betaalmethoden</Link>
          </li>
          <li onClick={handleNavLinkClick}>
            <Link to="/openingsuren">Openingsuren</Link>
          </li>
        </ul>
        <div className="Nav__burger" onClick={handleBurgerClick}>
          <div className="Nav__burger__line"></div>
          <div className="Nav__burger__line"></div>
          <div className="Nav__burger__line"></div>
        </div>
      </nav>
    </header>
  );
}

export default Nav;
