import React, { useState, useEffect } from "react";

import "./Menu.css";

import menu from "../../data/menu.json";
import prijslijst from "../../prijslijst.pdf";

function formatPrice(price) {
  return new Intl.NumberFormat("nl-BE", {
    style: "currency",
    currency: "EUR",
  }).format(price);
}

function Menu({ title }) {
  const [categoryId, setCategoryId] = useState(1);
  const [category, setCategory] = useState(
    menu.filter((category) => category.id === 1)[0]
  );
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    setCategory(menu.filter((category) => category.id === categoryId)[0]);
  }, [categoryId]);

  return (
    <main className="Menu__main">
      <h1 className="Menu__main__title">Menu</h1>
      <div className="Menu__main__wrapper">
        <ul className="Menu__main__categories">
          {menu.map((category) => (
            <li key={category.id} className="Menu__main__category">
              <h2
                onClick={() => setCategoryId(category.id)}
                className={
                  "Menu__main__category__title" +
                  (categoryId === category.id ? " active" : "")
                }
              >
                {category.name}
              </h2>
            </li>
          ))}
          <li>
            <a
              className="Menu__main__viewPDF"
              href={prijslijst}
              target="_blank"
              title="Bekijken in PDF-formaat"
            >
              Bekijken in PDF
            </a>
          </li>
        </ul>
        <ul className="Menu__main__items">
          <h1 className="Menu__main__items__title">{category.name}</h1>
          {category.data.map((item) => (
            <div className="Menu__main__item">
              <h2 className="Menu__main__item__name">{item.name}</h2>
              <p className="Menu__main__item__price">
                {item.price
                  ? formatPrice(item.price)
                  : `${formatPrice(item.price1)} / ${formatPrice(item.price2)}`}
              </p>
            </div>
          ))}
        </ul>
      </div>
    </main>
  );
}

export default Menu;
