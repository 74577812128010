import { db } from "../../firebase";
import emailjs from "emailjs-com";

const moment = require("moment");
const publicIp = require("public-ip");
const mailsCollection = db.collection("mails");

async function sendMail(name, email, phonenumber, subject, message) {
  const currentIp = await publicIp.v4({
    fallbackUrls: ["https://ifconfig.co/ip"],
  });
  let canSend = true;

  // Validating
  if (!name || !email || !subject || !message) {
    return { status: 400, message: "Gelieve de vereiste velden in te vullen!" };
  }

  const mailsWithCurrentIp = await mailsCollection
    .where("ip", "==", currentIp)
    .get();

  mailsWithCurrentIp.forEach((mailWithCurrentIp) => {
    const fiveMinutesAgo = moment().subtract(5, "minutes");
    const isFiveMinutesAgo = moment(mailWithCurrentIp.data().time).isBefore(
      fiveMinutesAgo
    );
    if (!isFiveMinutesAgo) {
      canSend = false;
    }
  });

  if (!canSend) {
    return {
      status: 429,
      message: "Je kan maar één keer om de 5 minuten sturen!",
    };
  }

  // Sending mail and saving to DB
  let mail = {
    ip: currentIp,
    name: name,
    email: email,
    phone: phonenumber ? phonenumber : "Niet opgegeven",
    subject: subject,
    message: message,
    time: Date.now(),
  };

  await mailsCollection
    .doc()
    .set(mail)
    .catch((err) => {
      return { status: 500, message: "Oeps! Foutje aan onze kant." };
    });

  const emailRes = await emailjs.send(
    "service_sxwsgsm",
    "template_uglr9b4",
    {
      Name: name,
      Email: email,
      Phonenumber: phonenumber ? phonenumber : "Niet opgegeven",
      Subject: subject,
      Message: message,
    },
    "user_pnShruUSwPBiieSMyHoe1"
  );

  if (emailRes.status === 200) {
    return { status: 200, message: "Email verzonden!" };
  } else {
    return { status: 500, message: "Oeps! Foutje aan onze kant." };
  }
}

export default sendMail;
