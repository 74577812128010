let day = new Date().getDay();

class Day {
  constructor(dayRef, openFrom) {
    this.dayRef = dayRef;
    this.openFrom = openFrom;
    this.now = new Date().getHours() * 60 + new Date().getMinutes();
  }

  giveColor() {
    let start;
    const end = day == 5 || day == 6 ? 24 * 60 : 23 * 60;
    console.log(end);
    if (this.openFrom === 10) {
      start = 10 * 60;
    } else if (this.openFrom === 11.5) {
      start = 11.5 * 60;
    }
    if (day === (3 || 4)) {
      this.dayRef.style.color = "red";
    } else {
      if (start <= this.now && this.now < end) {
        this.dayRef.style.color = "#7dff05";
      } else {
        this.dayRef.style.color = "red";
      }
    }
  }
}

function colorTable(
  sundayRefsArr,
  mondayRefsArr,
  tuesdayRefsArr,
  wednesdayRefsArr,
  thursdayRefsArr,
  fridayRefsArr,
  saturdayRefsArr
) {
  if (day === 0) {
    // Zondag
    sundayRefsArr.forEach(async (sundayRef) => {
      new Day(sundayRef, 10).giveColor();
    });
  }
  if (day === 1) {
    // Maandag
    mondayRefsArr.forEach(async (mondayRef) => {
      new Day(mondayRef, 11.5).giveColor();
    });
  }
  if (day === 2) {
    // Dinsdag
    tuesdayRefsArr.forEach(async (tuesdayRef) => {
      new Day(tuesdayRef, 11.5).giveColor();
    });
  }
  if (day === 3) {
    // Woensdag
    wednesdayRefsArr.forEach(async (wednesdayRef) => {
      new Day(wednesdayRef).giveColor();
    });
  }
  if (day === 4) {
    // Donderdag
    thursdayRefsArr.forEach(async (thursdayRef) => {
      new Day(thursdayRef).giveColor();
    });
  }
  if (day === 5) {
    // Vrijdag
    fridayRefsArr.forEach(async (fridayRef) => {
      new Day(fridayRef, 11.5).giveColor();
    });
  }
  if (day === 6) {
    // Zaterdag
    saturdayRefsArr.forEach(async (saturdayRef) => {
      new Day(saturdayRef, 11.5).giveColor();
    });
  }
}

export default colorTable;
