import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Import Components
import Nav from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";

// Import Pages
import Home from "./Pages/Home/Home";
import Menu from "./Pages/Menu/Menu";
import Afbeeldingen from "./Pages/Afbeeldingen/Afbeeldingen";
import Contact from "./Pages/Contact/Contact";
import Betaalmethoden from "./Pages/Betaalmethoden/Betaalmethoden";
import Openingsuren from "./Pages/Openingsuren/Openingsuren";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";

function App() {
  return (
    <Router>
      <Nav />
      <Switch>
        {/* Home Page */}
        <Route
          path="/"
          exact
          render={(props) => (
            <Home {...props} title="Herberg Breugelhoeve | Home" />
          )}
        />

        {/* Snacks Page */}
        <Route
          path="/menu"
          exact
          render={(props) => (
            <Menu {...props} title="Herberg Breugelhoeve | Menu" />
          )}
        />

        {/* Images Page */}
        <Route
          path="/afbeeldingen"
          exact
          render={(props) => (
            <Afbeeldingen
              {...props}
              title="Herberg Breugelhoeve | Afbeeldingen"
            />
          )}
        />

        {/* Contact Page */}
        <Route
          path="/contacteer-ons"
          exact
          render={(props) => (
            <Contact {...props} title="Herberg Breugelhoeve | Contacteer ons" />
          )}
        />
        {/* Payment options Page */}
        <Route
          path="/betaalmethoden"
          exact
          render={(props) => (
            <Betaalmethoden
              {...props}
              title="Herberg Breugelhoeve | Betaalmethoden"
            />
          )}
        />

        {/* Opening hours Page */}
        <Route
          path="/openingsuren"
          exact
          render={(props) => (
            <Openingsuren
              {...props}
              title="Herberg Breugelhoeve | Openingsuren"
            />
          )}
        />

        {/* Page not found */}
        <Route
          path="/"
          render={(props) => (
            <PageNotFound
              {...props}
              title="Herberg Breugelhoeve | Pagina niet gevonden"
            />
          )}
        />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
