import React, { useEffect } from "react";
import "./Betaalmethoden.css";

function Betaalmethoden({ title }) {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <main className="Betaalmethoden__main">
      <h1>Betaalmethoden</h1>
      <div className="Betaalmethoden__main__methoden">
        <div className="Betaalmethoden__main__methoden__methode">
          <img
            src={require("../../images/cashIcon.png").default}
            alt="Euroteken"
          />
          <h2>Cash</h2>
        </div>
        <div className="Betaalmethoden__main__methoden__methode">
          <img
            src={require("../../images/payconiqIcon.png").default}
            alt="Logo Payconiq"
          />
          <h2>Payconiq</h2>
        </div>
      </div>
    </main>
  );
}

export default Betaalmethoden;
