import React, { useEffect, useRef, useState } from "react";
import sendMail from "./sendMail";
import "./Contact.css";

function Contact({ title }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [statusMessage, setStatusMessage] = useState({});

  const formRef = useRef();

  useEffect(() => {
    document.title = title;
  }, [title]);

  async function handleFormSubmit(e) {
    e.preventDefault();
    setStatusMessage({ status: 102, message: "Aan het verwerken..." });
    const res = await sendMail(
      name.trim(),
      email.trim(),
      phonenumber.trim(),
      subject.trim(),
      message.trim()
    );
    setStatusMessage(res);
    if (res.status === 200) {
      setName("");
      setEmail("");
      setPhonenumber("");
      setSubject("");
      setMessage("");
    }
  }

  function returnRightColor(statusCode) {
    switch (statusCode) {
      case 400:
        return "red";
      case 429:
        return "red";
      case 500:
        return "red";
      case 200:
        return "rgb(125, 255, 5)";
      case 102:
        return "#FFB52E";
      default:
        return "red";
    }
  }

  return (
    <main className="Contact__main">
      <section className="Contact__main__titleSection">
        <h1>Contacteer ons!</h1>
      </section>
      <section className="Contact__main__infoSection">
        <h1>Info</h1>
        <div className="Contact__main__infoSection__info">
          <p>
            Telefoonnummer:&nbsp;
            <a href="tel:+32 (0) 3 345 32 95">+32 (0) 3 345 32 95</a>
          </p>
          <p>
            E-mail:&nbsp;
            <a href="mailto:herbergbreugelhoeve@gmail.com">
              herbergbreugelhoeve@gmail.com
            </a>
          </p>
          <p>Btw-nummer: BE0823840497</p>
        </div>
      </section>
      <section className="Contact__main__sendAMailSection">
        <h1>Stuur een mail</h1>
        <form
          className="Contact__main__sendAMailSection__form"
          onSubmit={handleFormSubmit}
          ref={formRef}
        >
          <div
            className="statusDiv"
            style={
              statusMessage.message
                ? {
                    display: "flex",
                    color: `${returnRightColor(statusMessage.status)}`,
                  }
                : { display: "none" }
            }
          >
            <p>{statusMessage.message}</p>
          </div>
          <label htmlFor="Name">Naam:</label>
          <input
            type="text"
            id="Name"
            className="Name"
            placeholder="Naam"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <label htmlFor="Email">E-mail:</label>
          <input
            type="email"
            id="Email"
            className="Email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label htmlFor="Phonenumber">Telefoonnummer (Optioneel):</label>
          <input
            type="tel"
            id="Phonenumber"
            className="Phonenumber"
            placeholder="Telefoonnummer"
            value={phonenumber}
            onChange={(e) => setPhonenumber(e.target.value)}
          />

          <label htmlFor="Subject">Onderwerp:</label>
          <input
            type="text"
            id="Subject"
            className="Subject"
            placeholder="Onderwerp"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />

          <label htmlFor="Message">Bericht:</label>
          <textarea
            type="text"
            id="Message"
            className="Message"
            placeholder="Bericht"
            rows="8"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />

          <button type="submit">Verstuur</button>
        </form>
      </section>
      <section className="Contact__main__googleMapsSection">
        <iframe
          title="Google maps Location"
          loading="lazy"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5003.85478240148!2d4.653808!3d51.165128!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa505b59f35a79725!2sHerberg%20Breugelhoeve!5e0!3m2!1snl!2sbe!4v1628095293027!5m2!1snl!2sbe"
        ></iframe>
      </section>
    </main>
  );
}

export default Contact;
