import React, { useEffect } from "react";

import "./Home.css";

function Home({ title }) {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <main className="Home__main">
      <section className="Home__main__about-us">
        <h1 className="Home__main__about-us__title">Over ons</h1>
        <p className="Home__main__about-us__text">
          Gezellig gelegen hoeve met aangenaam zomerterras.
          <br />
          <br />
          Een ruime keuze aan bieren waarvan 6 van het vat.
          <br />
          <br />
          Dicht gelegen aan de kesselse heide en diverse wandel- en fietspaden.
          <br />
          <br />
          Ook wij houden ons aan de regels van Covid-19.
        </p>
      </section>
      <section className="Home__main__image">
        <div />
      </section>
    </main>
  );
}

export default Home;
