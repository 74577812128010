import React from "react";

import "./Footer.css";

function Footer() {
  return (
    <footer>
      <p className="VAT-number">Btw-nummer: BE0823840497</p>
      <a
        href="https://www.jochemgroeneweg.be/"
        target="_blank"
        className="credits"
      >
        website by Jochem Groeneweg © 2022
      </a>
      <p className="address">Adres: Breugelhoevestraat 35, 2560 Nijlen</p>
    </footer>
  );
}

export default Footer;
