import React, { useEffect } from "react";

function PageNotFound({ title }) {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <main>
      <h1>Pagina Niet Gevonden!</h1>
    </main>
  );
}

export default PageNotFound;
