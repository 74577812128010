import React, { useEffect } from "react";

import colorTable from "./colorTable";

import "./Openingsuren.css";

function Openingsuren({ title }) {
  const sundayRefs = [];
  const mondayRefs = [];
  const tuesdayRefs = [];
  const wednesdayRefs = [];
  const thursdayRefs = [];
  const fridayRefs = [];
  const saturdayRefs = [];

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    colorTable(
      sundayRefs,
      mondayRefs,
      tuesdayRefs,
      wednesdayRefs,
      thursdayRefs,
      fridayRefs,
      saturdayRefs
    );
  });

  return (
    <main className="Openingsuren__main">
      <h1>Openingsuren</h1>
      <table className="Openingsuren__main__table">
        <thead>
          <tr>
            <th ref={(mondayRef) => mondayRefs.push(mondayRef)}>Maandag</th>
            <th ref={(tuesdayRef) => tuesdayRefs.push(tuesdayRef)}>Dinsdag</th>
            <th ref={(wednesdayRef) => wednesdayRefs.push(wednesdayRef)}>
              Woensdag
            </th>
            <th ref={(thursdayRef) => thursdayRefs.push(thursdayRef)}>
              Donderdag
            </th>
            <th ref={(fridayRef) => fridayRefs.push(fridayRef)}>Vrijdag</th>
            <th ref={(saturdayRef) => saturdayRefs.push(saturdayRef)}>
              Zaterdag
            </th>
            <th ref={(sundayRef) => sundayRefs.push(sundayRef)}>Zondag</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td ref={(mondayRef) => mondayRefs.push(mondayRef)}>
              Vanaf 11.30U
            </td>
            <td ref={(tuesdayRef) => tuesdayRefs.push(tuesdayRef)}>
              Vanaf 11.30U
            </td>
            <td ref={(wednesdayRef) => wednesdayRefs.push(wednesdayRef)}>
              Gesloten
            </td>
            <td ref={(thursdayRef) => thursdayRefs.push(thursdayRef)}>
              Gesloten
            </td>
            <td ref={(fridayRef) => fridayRefs.push(fridayRef)}>
              Vanaf 11.30U
            </td>
            <td ref={(saturdayRef) => saturdayRefs.push(saturdayRef)}>
              Vanaf 11.30U
            </td>
            <td ref={(sundayRef) => sundayRefs.push(sundayRef)}>Vanaf 10U</td>
          </tr>
        </tbody>
      </table>
    </main>
  );
}

export default Openingsuren;
