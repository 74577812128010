// Images page
import React, { useEffect, useRef } from "react";

import photo1 from "../../images/foto1.jpg";
import photo2 from "../../images/foto2.jpg";
import photo3 from "../../images/foto3.jpg";
import photo4 from "../../images/foto4.jpg";
import photo5 from "../../images/foto5.jpg";
import photo6 from "../../images/foto6.jpg";
import photo7 from "../../images/foto7.jpg";
import photo8 from "../../images/foto8.jpg";
import photo9 from "../../images/foto9.jpg";

import "./Afbeeldingen.css";

function Afbeeldingen({ title }) {
  const modalRef = useRef(null);
  const modalImageRef = useRef(null);

  const allPhotos = [
    { photo1: photo1 },
    { photo2: photo2 },
    { photo3: photo3 },
    { photo4: photo4 },
    { photo5: photo5 },
    { photo6: photo6 },
    { photo7: photo7 },
    { photo8: photo8 },
    { photo9: photo9 },
  ];
  useEffect(() => {
    document.title = title;
  }, [title]);

  function handlePhotoClick(e) {
    const currentPhotoName = e.target.dataset.photo;
    allPhotos.forEach((photoDict) => {
      if (Object.keys(photoDict)[0] === currentPhotoName) {
        openModal(photoDict[currentPhotoName]);
      }
    });
  }

  function openModal(img) {
    if (img !== undefined) {
      modalImageRef.current.src = img;

      modalRef.current.classList.toggle("open");
      modalImageRef.current.classList.toggle("open");

      document.querySelector("html").style.overflow = "hidden";
      document.body.style.overflow = "hidden";
      document.querySelector("html").style.touchAction = "none";
      document.body.style.touchAction = "none";
    }
  }

  function handleModalClick(e) {
    if (e.target.classList.contains("Afbeeldingen__main__modal")) {
      closeModal();
    }
  }

  function closeModal() {
    modalRef.current.classList.remove("open");
    modalImageRef.current.classList.remove("open");

    document.querySelector("html").style.overflowY = "auto";
    document.body.style.overflowY = "auto";
    document.querySelector("html").style.touchAction = "auto";
    document.body.style.touchAction = "auto";

    modalRef.current.src = "";
  }

  return (
    <main>
      <div className="Afbeeldingen__main__images">
        <div>
          <img
            src={photo1}
            alt="photo1"
            data-photo="photo1"
            onClick={handlePhotoClick}
          />
        </div>
        <div>
          <img
            src={photo2}
            alt="photo2"
            data-photo="photo2"
            onClick={handlePhotoClick}
          />
        </div>
        <div>
          <img
            src={photo3}
            alt="photo3"
            data-photo="photo3"
            onClick={handlePhotoClick}
          />
        </div>
        <div>
          <img
            src={photo4}
            alt="photo4"
            data-photo="photo4"
            onClick={handlePhotoClick}
          />
        </div>
        <div>
          <img
            src={photo5}
            alt="photo5"
            data-photo="photo5"
            onClick={handlePhotoClick}
          />
        </div>
        <div>
          <img
            src={photo6}
            alt="photo6"
            data-photo="photo6"
            onClick={handlePhotoClick}
          />
        </div>
        <div>
          <img
            src={photo7}
            alt="photo7"
            data-photo="photo7"
            onClick={handlePhotoClick}
          />
        </div>
        <div>
          <img
            src={photo8}
            alt="photo8"
            data-photo="photo8"
            onClick={handlePhotoClick}
          />
        </div>
        <div>
          <img
            src={photo9}
            alt="photo9"
            data-photo="photo9"
            onClick={handlePhotoClick}
          />
        </div>
      </div>
      <div
        className="Afbeeldingen__main__modal"
        ref={modalRef}
        onClick={handleModalClick}
      >
        <img
          src=""
          ref={modalImageRef}
          alt=""
          className="Afbeeldingen__main__modal__full-image"
        />
        <p
          className="Afbeeldingen__main__modal__close-button"
          onClick={closeModal}
        >
          X
        </p>
      </div>
    </main>
  );
}

export default Afbeeldingen;
